#region-revamp {
    display: grid;
    height: unset;

    #mainHeader {
        position: sticky;
        height: var(--headerHeight);
        top: calc(var(--announcementHeight) * -1);
    }
}

.reg #regional-banner-promo {
    margin: 0;

    @media screen and (min-width: 768px) {
        margin: 10px 50px 0;
    }
}

.reg #regional-banner-promo .regional-banner-promo {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
    max-width: none;
}

.reg {
    min-height: calc(100vh - var(--navbarHeight));
}

.reg__header {
    display: grid;
    min-height: 98px;
    padding: 20px 40px;

    @media screen and (min-width: 768px) and (max-width: 1279px) {
        min-height: 104px;
        padding: 50px 20px 25px;
    }

    @media screen and (min-width: 1280px) {
        padding: 50px 50px 25px;
    }
}

.reg__title {
    margin: 0;
    font-size: clamp(22px, 2vw, 30px);
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: 1px;
    text-align: center;
    color: black;

    @media screen and (min-width: 768px) {
        text-align: left;
    }
}

.reg__main {
    position: relative;
    min-height: calc(100vh - var(--navbarHeight));
    max-width: 100%;
    transition: grid-template-columns 0.2s linear;

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: 55% 45%;
        grid-template-areas:
            "filter-bar filter-bar"
            "content map";
    }
}

.reg__main.reg__main--our-floorplans,
.reg__main--grid {
    @media screen and (min-width: 1024px) {
        grid-template-columns: 100% 0;
    }
}

.reg__cards.reg__cards--our-floorplans strong {
    letter-spacing: unset;
}

.reg__cards--communities .reg__card-address {
    text-transform: uppercase;
}

.reg__content {
    position: sticky;
    top: calc(var(--navbarHeight) - 70px);
    grid-area: content;
    z-index: 2;

    @media screen and (min-width: 1024px) {
        position: relative;
        top: unset;
        z-index: unset;
    }
}

.reg__nav {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 0;
    height: 70px;
    padding: 15px 20px;
    padding: 15px 0 15px 20px;
    gap: 12px;
    max-width: 100vw;
    overflow-x: auto;
    background: white;
    z-index: 2;
    transition: grid-template-columns 0.2s linear;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media screen and (min-width: 768px) and (max-width: 1279px) {
        padding: 15px 20px;
    }

    @media screen and (min-width: 1280px) {
        grid-template-columns: repeat(4, 150px) 0;
        padding: 15px 50px;
        gap: 12px;
    }
}

// Hide .reg__nav scrollbar across browsers
.reg__nav::-webkit-scrollbar {
    display: none;
}

.reg__nav .reg__nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    white-space: nowrap;
    color: black;
    border-radius: 10px;
    border: 2px solid #D9D9D9;
    background: #D9D9D9;
    transition: color 0.2s ease-out, background 0.2s ease-out;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
        padding: 0 15px;
    }
}

.reg__nav .reg__nav-link:hover,
.reg__nav .reg__nav-link:focus-visible {
    background: #D8D8D8;
}

.reg__nav .reg__nav-link--active,
.reg__nav .reg__nav-link--active:hover,
.reg__nav .reg__nav-link--active:focus-visible {
    background: white;
}

.reg__map {
    position: sticky;
    top: var(--navbarHeight);
    height: calc(100vh - var(--navbarHeight));
    grid-column: unset;
    grid-row: unset;
    grid-area: map;
}

/* Floorplans do not have a map */
.reg__main--our-floorplans .reg__map,
.reg__main--grid .reg__map {
    display: none;

    @media screen and (min-width: 1024px) {
        display: block;
    }
}

.reg__map-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #EBEBEB;
}

.reg__map-container .angular-google-map,
.reg__map-container .angular-google-map-container,
.reg__map-container #map {
    display: block;
    width: 100%;
    height: 100%;
}

.reg__marker {
    position: relative;
}

.reg__marker::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/images/site/global/pin.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

}

.reg__marker--model-home::before {
    background-image: url('/images/site/global/pin-model-home.svg');
}

.reg__marker img {
    pointer-events: none;
    position: relative;
    display: block;
    opacity: 0;
    transition: opacity 0.2s ease-out;
}

.reg__marker:hover img,
.reg__marker--hover img {
    opacity: 1;
}

.reg__marker .reg__marker-label {
    position: absolute;
    top: 0;
    left: 50%;
    padding: 8px 10px;
    font-family: $open-sans;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    color: black;
    border-radius: 5px;
    background: white;
    transform: translate(-50%, -100%);
    opacity: 0;
    transition: opacity 0.2s ease-out;
}

.reg__marker:hover .reg__marker-label,
.reg__marker--hover .reg__marker-label {
    opacity: 1;
}

.reg__listings {
    position: relative;
}


.reg__mobile-filters {
    @media screen and (min-width: 1200px) {
        // display: none;
    }
}

.reg__filters {
    display: none;
    grid-template-columns: repeat(12, auto);
    max-width: 100%;
    gap: 12px;
    padding: 20px;
    transition: max-width 0.2s linear;
    background-color: white;
    z-index: 10;

    @media screen and (min-width: 1200px) {
        // display: grid;
    }

    @media screen and (min-width: 1280px) {
        padding: 20px 50px;
    }

    grid-area: filter-bar;
}

.reg__filter {
    position: relative;
}

.reg__filter-trigger::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    display: block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #a9a9a9;
    border-bottom: 2px solid #a9a9a9;
    transition: transform .2s ease-in-out, border-color .2s ease-out;
    transform: rotate(-45deg) translateY(-100%);
}

.reg__filter-trigger {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-transform: capitalize;
    color: #212121;
    border: 2px solid #d9d9d9;
    border-radius: 10px;
    background: white;
    transition: color 0.2s ease-out, border-color 0.2s ease-out, background 0.2s ease-out;
}

.reg__filter-trigger[aria-expanded='true'],
.reg__filter-trigger--active {
    color: white;
    border-color: #BA1F31;
    background: #BA1F31;
}

.reg__filter-trigger[aria-expanded='true']::after,
.reg__filter-trigger--active::after {
    border-left: 2px solid white;
    border-bottom: 2px solid white;
}

.reg__mobile-filters-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    min-width: 85px;
    min-height: 37.5px;
    max-width: 140px;
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-transform: capitalize;
    color: #212121;
    border: 0;
    border-radius: 10px;
    background: white;
    box-shadow: 0 0 0 2px rgba(#d9d9d9, 0);
    transition: color 0.2s ease-out, box-shadow 0.2s ease-out, background 0.2s ease-out;
}

.reg__mobile-filters-trigger span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background: #BA1F31;
    border-radius: 50%;
}

.reg__mobile-filters-trigger:hover,
.reg__mobile-filters-trigger:focus-visible {
    color: #BA1F31;
    box-shadow: 0 0 0 2px rgba(#BA1F31, 0.5);
}

.reg__filter-trigger:hover,
.reg__filter-trigger:focus-visible {
    color: #BA1F31;
    border-color: #BA1F31;
    background: white;
}

.reg__filter-trigger:hover::after,
.reg__filter-trigger:focus-visible::after {
    border-left: 2px solid #BA1F31;
    border-bottom: 2px solid #BA1F31;
}

.reg__mobile-filters-container {
    display: flex;
    padding: 2px;
    gap: 2px;
    border-radius: 12px;
    background: #d9d9d9;
}

.reg__display-option-empty-button {
    display: none;
    align-items: center;
    justify-content: center;
    width: 35px;
    border-radius: 0 10px 10px 0;
    border: 0;
    background: white;
    box-shadow: 0 0 0 2px rgba(#d9d9d9, 0);
    transition: color 0.2s ease-out, box-shadow 0.2s ease-out, background 0.2s ease-out;
}

.reg__display-options--filtered .reg__mobile-filters-trigger {
    border-radius: 10px 0 0 10px;
}

.reg__display-options--filtered .reg__display-option-empty-button {
    display: flex;
}

.reg__filter-options {
    pointer-events: none;
    position: absolute;
    display: grid;
    top: 100%;
    left: 0;
    gap: 5px;
    padding: 10px;
    opacity: 0;
    background: white;
    border-radius: 5px;
    box-shadow: -4px 4px 10.6px 0px rgba(0, 0, 0, 0.15);
    z-index: 1;
    transition: opacity 0.2s ease-out;
}

.reg__filter-options[aria-hidden='false'] {
    pointer-events: auto;
    opacity: 1;
}

.reg__filter-option {
    display: grid;
    grid-auto-flow: column dense;
    align-items: center;
    gap: 5px;
}

.reg__filter-option--checkbox {
    grid-auto-columns: max-content 1fr;
}

.reg__filter-option--radio {
    grid-auto-columns: 1fr;
}

.reg__filter-option input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip-path: inset(50%);
    appearance: none;
}

.reg__filter-option label {
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    white-space: nowrap;
    color: black;
}

.reg__filter-option input:checked+label {
    color: #BA1F31;
    font-weight: 700;
}

.reg__results {
    position: sticky;
    display: grid;
    top: var(--navbarHeight);
    z-index: 2;
    background-color: white;
    padding: 20px;
    gap: 10px;
    border-top: 2px solid #d9d9d9;

    @media screen and (min-width: 1280px) {
        gap: 20px;
        padding: 20px 50px;
    }
}

.reg__results-header {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, max-content));
    justify-content: space-between;
    align-items: center;
    text-align: right;
    gap: 10px;

    @media screen and (min-width: 1280px) {
        gap: 20px;
    }

    @media screen and (min-width: 1488px) {
        grid-template-columns: max-content max-content max-content 1fr;
        justify-content: unset;
    }
}

.reg__main--our-floorplans .reg__results-header,
.reg__main--grid .reg__results-header {
    @media screen and (min-width: 1280px) {
        grid-template-columns: max-content max-content max-content 1fr;
        justify-content: unset;
    }
}

.reg__results-count {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.3;
    text-align: left;
    color: black;

    @media screen and (min-width: 768px) {
        width: unset;
        font-size: 14px;
    }

    @media screen and (min-width: 1488px) {
        width: 160px;
        justify-self: unset;
    }

    @media screen and (min-width: 1730px) {
        width: 180px;
    }

    @media screen and (min-width: 1800px) {
        width: unset;
    }
}

.reg__main--our-floorplans .reg__results-count,
.reg__main--grid .reg__results-count {
    @media screen and (min-width: 1488px) {
        width: unset !important;
    }
}

.reg__results-divider {
    display: none;

    @media screen and (min-width: 1488px) {
        display: block;
    }
}

.reg__display-options {
    display: grid;
    grid-auto-flow: column dense;
    grid-auto-columns: max-content;
    justify-content: space-between;
    grid-column: span 2;
    gap: 10px;

    @media screen and (min-width: 1488px) {
        justify-content: flex-end;
        grid-column: unset;
    }
}

.reg__main--grid .reg__display-options,
.reg__main--our-floorplans .reg__display-options {
    @media screen and (min-width: 1280px) {
        justify-content: flex-end;
    }
}

.reg__display-option {
    display: grid;
    align-items: center;
}

.reg__display-option--display {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    background-color: #d9d9d9;
    border-radius: 12px;
    padding: 2px;
}

.reg__display-option--clear {
    display: none;
}

.reg__display-options--filtered .reg__display-option--clear {
    @media screen and (min-width: 1488px) {
        display: grid;
        grid-template-columns: max-content max-content;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }
}

.reg__display-option-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    min-height: 30px;
    font-size: 12px;
    color: black;
    border: 0;
    background: transparent;
    transition: color 0.2s ease-out;
    padding: 5px 10px;
    border-radius: 10px;

    @media screen and (min-width: 768px) {
        font-size: 14px;
    }
}

.reg__display-option-button span {
    font-weight: 700;
}

.reg__display-option-button svg {
    display: none !important;
    width: 12px;
    height: 12px;

    @media screen and (min-width: 400px) {
        display: block !important;

        &.hidden-xs {
            display: none !important;
        }

        &.visible-xs {
            display: block !important;
        }
    }

    @media screen and (min-width: 768px) {
        width: 16px;
        height: 16px;

        &.visible-xs {
            display: none !important;
        }

        &.hidden-xs {
            display: block !important;
        }
    }
}

.reg__display-option-button:hover,
.reg__display-option-button:focus-visible,
.reg__display-option-button--active {
    color: #BA1F31;
}

.reg__display-option-button--active {
    background-color: white;
}

.reg__display-option-sort {
    position: relative;
}

.reg__display-option-sort::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 100%;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="14" height="7" fill="none" viewBox="0 0 14 7"%3E%3Cpath stroke="%23000" d="m1 .5 6 5 6-5"/%3E%3C/svg%3E');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.reg__display-option-label {
    font-size: 14px;
    font-weight: 700;
    color: black;
}

.reg__display-option-select {
    cursor: pointer;
    width: 140px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: black;
    border: 0;
    background: transparent;

    @media screen and (min-width: 768px) {
        width: unset;
    }
}

.reg__pagination-container {
    display: none;
    justify-content: center;
    padding: 20px;

    @media screen and (min-width: 1024px) {
        display: flex;
    }

    @media screen and (min-width: 1280px) {
        padding: 20px 50px;
    }
}

.reg__main--grid .reg__pagination-container {
    display: flex;
}

.reg__pagination-container .reg__pagination {
    height: 40px;
    gap: 2px;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-columns: unset;
    justify-content: space-between;
}

.reg__pagination {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    gap: 5px;
    padding: 2px;
    font-size: 12px;
    color: black;
    border-radius: 12px;
    background-color: #d9d9d9;
}

.reg__pagination-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;
    padding: 0;
    color: black;
    border-radius: 10px;
    border: 0;
    background: white;
}

.reg__pagination-btn:hover,
.reg__pagination-btn:focus-visible {
    color: #BA1F31;
}

.reg__pagination-btn[disabled],
.reg__pagination-btn[disabled]:hover,
.reg__pagination-btn[disabled]:focus-visible {
    pointer-events: none;
    color: #a9a9a9;
    background: #f9f9f9;
}

.reg__pagination-group,
.reg__pagination-pages {
    display: grid;
    grid-auto-flow: column;
    gap: 2px;
    align-items: center;
    height: 100%;
}

.reg__pagination-divider {
    padding: 0 5px;
    font-size: 8px;
    letter-spacing: 1px;
    color: #888;
}

.reg__pagination-page--active {
    pointer-events: none;
    color: white;
    background: #BA1F31;
}

.reg__pagination-page--active:hover,
.reg__pagination-page--active:focus-visible {
    color: white;
    background: #BA1F31;
}

// Cards
.reg__cards {
    display: none;
    gap: 15px;
    padding: 20px;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 20px;
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }

    @media screen and (min-width: 1280px) {
        padding: 20px 80px;
    }
}

/* Floorplans do not have a map */
.reg__main.reg__main--our-floorplans .reg__cards,
.reg__main--grid .reg__cards {
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media screen and (min-width: 1600px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.reg__card__container {
    position: relative;
}

.reg__card {
    position: relative;
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: minmax(279px, 1fr);
    overflow: hidden;
    order: 3;
    border-radius: 20px;
    height: 100%;
    min-height: 520px;

    &:nth-child(1) {
        order: 1;
    }
}

.reg__card::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border: 2px solid #a6a6a6;
    transform: scale(1.05);
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    border-radius: 20px;
}

.reg__card:hover::after,
.reg__card--active::after {
    opacity: 1;
    transform: scale(1);
}

.reg__card-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.reg__card-image {
    position: relative;
    height: 0;
    padding-top: calc(100% * (360 / 540));
    background: #a6a6a6;
    border-radius: 26px 26px 0 0;

    .banner-community {
        position: absolute;
        top: -5px;
        right: -5px;

        &.featured-community {
            top: auto;
            right: auto;
            left: 0;
            bottom: 20px;
        }
    }

    a.btn {
        position: absolute;
        bottom: 20px;
        right: 0;
        padding: 10px 20px;
        @include flex-param(row, space-between, center);
    }

    .banner-home {
        position: absolute;

        &.fresh-savings {
            top: 15px;
            right: 0;
        }

        &.blitz {
            top: 0;
            left: 0;
        }

        &.red-bow {
            top: 0;
            left: 0;
            right: auto;
        }

        &.lucky-you {
            top: 0;
            left: 0;
            right: auto;
        }

        &.open-house,
        &.decorated-home {
            top: 0;
            left: 0;
            right: auto;
            width: 120px;
            height: auto;
        }

        &.unlock-savings,
        &.unlocked {
            position: absolute;
            top: 15px;
            left: 15px;
            width: 120px;
            height: auto;
            border-radius: 8px;
        }
    }

    .banner {
        position: absolute;
        top: 10px;
        right: 15px;
        color: black;
        text-transform: capitalize;
        font-weight: 600;
        background-color: white;
        padding: 7px 20px;
        border-radius: 10px;
        font-size: 13px;
        width: 25%;
        min-width: fit-content;
        text-align: center;
    }
}

.reg__modal__content .banner-home.fresh-savings {
    top: 50px;
    right: 0;
}

.reg__card-image--single img:not(.banner-home) {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.reg__card-image-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #D4D4D4;
        object-fit: cover;
        object-position: center;
    }
}

.reg__card-image-slider .slick-dots {
    position: absolute;
    display: grid;
    grid-auto-flow: column dense;
    grid-auto-columns: max-content;
    justify-content: center;
    gap: 5px;
    bottom: 10px;
    width: 100%;
    z-index: 1;
}

.reg__card-image-slider .slick-dots button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    margin: 0;
    padding: 0;
    font-size: 0;
    border: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.8);
    transition: background 0.2s ease-out;
}

.reg__card-image-slider .slick-dots button:hover,
.reg__card-image-slider .slick-dots button:focus-visible,
.reg__card-image-slider .slick-dots .slick-active button {
    background: white;
}

.reg__card-image-slider .slick-list,
.reg__card-image-slider .slick-track,
.reg__card-image-slider .slick-slide {
    height: 100%;
}

.reg__card-image-slider .slick-slide {
    position: relative;
}

.reg__card-image-slide-left,
.reg__card-image-slide-right {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    padding: 0;
    color: black;
    border: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    transform: translateY(-50%);
    transition: background 0.2s ease-out, opacity 0.2s ease-in-out;
    opacity: 1;
    z-index: 1;

    @media screen and (min-width: 768px) {
        opacity: 0;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
}

.reg__card-image-slide-left:hover,
.reg__card-image-slide-left:focus-visible,
.reg__card-image-slide-right:hover,
.reg__card-image-slide-right:focus-visible {
    background: white;
}

.reg__card-image-slide-left {
    left: 10px;
}

.reg__card-image-slide-right {
    right: 10px;
}

.reg__card:hover .reg__card-image-slide-left,
.reg__card:hover .reg__card-image-slide-right {
    @media screen and (min-width: 768px) {
        opacity: 1;
    }
}

.reg__card-content {
    display: grid;
    grid-auto-flow: row dense;
    grid-auto-rows: auto;
    align-content: space-between;
    gap: 15px;
    padding: 15px;
    border: 2px solid #D4D4D4;
    border-top: 0;
    border-radius: 0 0 20px 20px;
}

.reg__card-titles {
    display: flex;
    justify-content: space-between;
}

.reg__card-heading {
    display: grid;
    gap: 2px;

    /* The card heading, body, and button(s) are technically aligned, but marketing preferred a visual fix */
    padding-left: 2px;
    padding-right: 2px;
    text-align: start;
}

.reg__card-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
    color: black;
    letter-spacing: 1px;
    margin-bottom: 0 !important;
}

.reg__card-address {
    font-size: .7rem;
    line-height: 1.3;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: black;
    font-weight: 300;
    text-align: start;

    &.occupancy {
        font-size: 13px;
        text-transform: unset;
        letter-spacing: unset;
    }
}

.reg__card-address.red {
    font-weight: 600;
}

.reg__card-features {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.3;
    color: #BA1F31
}

.reg__card-price {
    font-weight: 700;
    margin-top: 12px;
    display: block;

    .line-through {
        font-weight: 400;
        text-decoration-color: #BA1F31;
    }
}

.reg__card-body {
    font-size: 13px;
    line-height: 1.5;
    color: black;

    /* The card heading, body, and button(s) are technically aligned, but marketing preferred a visual fix */
    padding-left: 2px;
    padding-right: 2px;

    span>strong {
        letter-spacing: .5px;
    }

    .line-through {
        text-decoration: line-through;

        &.line-through-red {
            text-decoration-color: $red;
        }
    }

    .snapshot {
        display: flex;
        align-items: center;
        gap: 7.5px;
        justify-content: start;

        li {
            display: flex;
            align-items: center;
            gap: 7.5px;
        }

        svg {
            @include svgcolor(black);
            width: auto;
            height: 15px;
        }

        @media screen and (max-width: 429px) {
            gap: 3px;

            li {
                gap: 5px;
                font-size: 11px;
            }

        }
    }
}

.reg__card-footer {
    /*  display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); */
    display: flex;
    gap: 8px;
}

.reg__card-button {
    display: flex;
    align-items: center;
    gap: 7.5px;
    justify-content: center;
    height: 37px;
    padding: 0 10px;
    font-size: 14px;
    color: white;
    background: #BA1F31;
    border: 1px solid #BA1F31;
    transition: color 0.2s ease-out, background 0.2s ease-out, border-color 0.2s ease-out;
    width: 100%;
    border-radius: 10px;
    z-index: 1;

    svg {
        width: auto;
        height: 15px;
    }
}

.reg__card-button:hover,
.reg__card-button:focus-visible {
    color: white;
    background: black;
    border-color: black;
}

.reg__card--loading {
    position: relative;
    height: 450px;
    overflow: hidden;
    background: #efefef;
    background-image: linear-gradient(45deg,
            #efefef 12.5%,
            #f5f5f5 12.5%,
            #f5f5f5 25%,
            #efefef 25%,
            #efefef 37.5%,
            #f5f5f5 37.5%,
            #f5f5f5 50%,
            #efefef 50%,
            #efefef 62.5%,
            #f5f5f5 62.5%,
            #f5f5f5 75%,
            #efefef 75%,
            #efefef 87.5%,
            #f5f5f5 87.5%,
            #f5f5f5 100%);
    background-size: 200% 200%;
    border-radius: 20px;
    animation: loadingbackground 1.5s infinite linear;
}

@keyframes loadingbackground {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

.promo__card {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    order: 2;
    border-radius: 5px;

    .blur {
        background-size: cover;
        background-position: center;
        filter: blur(15px);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }

    .promo__card-link {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        border: 0;
        background: transparent;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.promo__card-temp {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    min-height: 350px;
    border: 2px solid #ba1f31;
    background-image: linear-gradient(to bottom,
            #da1829 0%,
            #da1829 49.99%,
            #840808 50%,
            #840808 100%);
}

.promo__card--red-white {
    background-image: linear-gradient(to bottom,
            #d52735 0%,
            #d52735 49.99%,
            #ffffff 50%,
            #ffffff 100%);
}
.promo__card--red {
    background-image: none;
    background-color: #ba1f31;
}
.promo__card--green {
    background-image: none;
    background-color: #085230;
}

.promo__card--white {
    background-image: none;
    background-color: #ffffff;
}

.reg__modal--tour {
    padding: 0;
    border: 0;
    border-radius: 3px;
}

.reg__modal--tour::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
}

.reg__modal--tour .reg__modal__content {
    margin: 0;
}

.reg__modal--tour .schedule-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    flex-direction: column;
    padding: 65px 30px;

    @media screen and (max-width: 768px) {
        padding: 40px 20px;
    }

    position: relative;
    h3.title {
        font-size: 26px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: 0.025em;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: white;
        @media screen and (min-width: 768px) {
            font-size: 30px;
        }
    }
    p.subtitle {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.025em;
        text-align: center;
        margin-bottom: 20px;
        color: white;
        @media screen and (min-width: 768px) {
            font-size: 20px;
        }
    }
    h3.type-xl {
        text-transform: uppercase;
        color: white;
        font-size: 1.75rem;
        letter-spacing: 2px;
        margin: 10px 0;

        @include phone {
            text-align: center;
            margin: 10px 15px;
            font-size: 1.5rem;
        }
    }

    a.type-lg {
        color: white;
        font-size: 1.4rem;
        letter-spacing: 1px;
        font-style: italic;
        text-transform: uppercase;
        font-weight: 700;

        @include phone {
            text-align: center;
            margin: 10px 15px;
            font-size: 1.25rem;
        }
    }

    .sales-agent {
        width: 225px;
        height: 250px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;

        @include phone {
            display: none;
        }
    }

    #modal-promo-form form {
        display: grid;
        gap: 20px;

        .hs-main-font-element {
            line-height: 1.5;
        }
        .hs-form-field label {
            margin-bottom: 5px;
        }

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            .hs-fieldtype-booleancheckbox,
            .hs-fieldtype-booleancheckbox + div,
            .hs-submit {
                grid-column: 1 / span 2;
            }
            .hs-submit {
                display: flex;
                justify-content: center;
            }
        }
    }

    #overview-hs-schedule-form,
    #modal-promo-form {
        display: block;
        max-width: 1200px;
        margin-top: 50px;
        position: relative;

        form {

            fieldset input,
            input {
                width: 100%;
                color: black;
                border: none;
                margin: 0;
                padding: 5px 15px;
                line-height: 2rem;

                &::placeholder {
                    color: black;
                    font-weight: 300;
                    letter-spacing: 1.5px;
                }
            }

            fieldset select,
            select {
                width: 100%;
                margin: 0;
                line-height: 2rem;
                padding: 5px 15px;
            }

            fieldset .hs-form-field .input,
            .hs-form-field .input {
                position: relative;
                margin: 0;
            }

            fieldset .hs-fieldtype-select .input::after,
            .hs-fieldtype-select .input::after {
                pointer-events: none;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: rotate(-45deg) translateY(-75%);
            }

            fieldset .hs-error-msgs,
            .hs-error-msgs {
                padding-block: 5px 0;
            }

            fieldset input[type="checkbox"],
            input[type="checkbox"] {
                accent-color: $red;
                display: inline-block;
                width: fit-content;
            }

            fieldset .inputs-list,
            fieldset .hs-richtext,
            .inputs-list,
            .hs-richtext {
                width: 75%;
                display: block;
                margin: 0 auto;
                text-align: center;

                @include phone {
                    width: 100%;
                }
            }

            fieldset .inputs-list,
            .inputs-list {
                margin-top: 30px;

                span {
                    font-size: .85rem;
                }
            }

            fieldset {
                display: grid;
                max-width: 100%;
                gap: 8px;
                margin-bottom: 8px;
            }

            input[type="submit"] {
                background-color: $red;
                padding: 10px 15px;
                color: white;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 1rem;
                border: none;
                display: block;
                margin: 15px auto;
            }
        }

        fieldset .hs-form-field,
        .hs-form-field {
            width: unset;
        }

        @media screen and (min-width: 768px) {
            fieldset.form-columns-3 {
                display: flex;
                flex-wrap: wrap;
            }

            fieldset.form-columns-3 .hs-form-field {
                min-width: calc(50% - 4px);
                flex-grow: 1;
                flex-shrink: 1;
            }
        }

        @media screen and (min-width: 1024px) {
            fieldset.form-columns-2 {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            fieldset.form-columns-3 {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            fieldset.form-columns-3 .hs-form-field {
                min-width: unset;
                flex-grow: unset;
                flex-shrink: unset;
            }
        }
    }
}

.reg__modal__close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: white;
    border: 0;
    background: transparent;
    z-index: 1;
    transition: color 0.2s ease-out;
}

.reg__modal__close:hover,
.reg__modal__close:focus-visible {
    color: #BA1F31;
}

.reg__modal--filters {
    padding: 0;
    border: 0;
    border-radius: 3px;
    overflow: hidden;
}

.reg__modal--filters .reg__modal__close {
    color: black;
}

.reg__modal--filters .reg__modal__close:hover,
.reg__modal--filters .reg__modal__close:focus-visible {
    color: #BA1F31;
}

.reg__modal__form {
    display: grid;
    padding: 40px 30px 15px;
    gap: 20px;
}

.reg__modal--filters .reg__modal__form {
    width: 340px;
    max-width: 90vw;
}

.reg__modal__form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.reg__modal__form-body {
    display: grid;
    gap: 20px;
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.reg__modal__form-title {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    color: black;
}

.reg__modal__filter {
    display: grid;
    gap: 4px;
}

.reg__modal__filter select {
    width: 100%;
}

.reg__modal__filter-label {
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    color: black;
}

.reg__modal__filter-select {
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: black;
    border: 1px solid #D4D4D4;
    border-radius: 5px;
    background: white;
}

.reg__modal__filter-select--selected {
    font-weight: 600;
}

.reg__modal__form-submit {
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: white;
    border: 0;
    background: #BA1F31;
    transition: background 0.2s ease-out;
}

.reg__modal__form-submit:hover,
.reg__modal__form-submit:focus-visible {
    background: black;
}

.reg__modal__form-reset {
    padding: 10px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: #737373;
    border: 0;
    background: transparent;
    transition: color 0.2s ease-out;
}

.reg__modal__form-reset:hover,
.reg__modal__form-reset:focus-visible {
    color: black;
}

.reg__modal--flyout {
    pointer-events: none;
    background-color: transparent;
    padding: 0;
    border: 0;
    top: 0;
    left: 0;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0);
    transition: background 0.2s ease-out;

    .reg__modal__close {
        background-color: black;
    }

    .reg__card {
        height: unset;
        background-color: #fff;
    }

    .reg__card-image .banner {
        right: 50px;
    }
}

.reg__modal--flyout[open] {
    position: fixed;
    pointer-events: auto;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.reg__modal--flyout .reg__modal__content {
    display: grid;
    width: calc(100% - 40px);
    max-width: 350px;
}

.reg__modal--flyout .reg__modal__close {
    position: relative;
    justify-self: flex-end;
    top: 0;
    right: 0;
    font-size: 14px;
    height: 27px;
    border-radius: 10px;
    border: 1px solid white;
    transform: translate(-12px, 37px);
    transition: background 0.2s ease-out;
}

.reg__modal--flyout .reg__modal__close:hover,
.reg__modal--flyout .reg__modal__close:focus-visible {
    color: white;
    background-color: #BA1F31;
}

.reg__results-empty {
    display: grid;
    grid-template-columns: 1fr max-content;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 20px;
    line-height: 1.4;
    border: 1px solid #D4D4D4;
    // @media screen and (min-width: 1280px) {
    //     gap: 30px;
    //     padding: 20px 50px;
    // }
    // @include phone {
    //     grid-column: 1 / span 2;
    //     grid-template-columns: none;
    //     justify-content: center;
    // }
}

.reg__results-empty-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    padding: 0 10px;
    font-size: 14px;
    color: white;
    background: #BA1F31;
    border: 1px solid #BA1F31;
    transition: color 0.2s ease-out, background 0.2s ease-out, border-color 0.2s ease-out;
}

.reg__results-empty-button:hover,
.reg__results-empty-button:focus-visible {
    color: white;
    background: black;
    border-color: black;
}

.reg__disclaimer {
    margin: 50px auto 0;
    width: 85%;
    max-width: 1440px;
    font-style: italic;
    font-size: 14px;
    line-height: 1.6;
}

.reg__seo-wrapper {
    margin-top: 50px;
    width: 100%;
    max-width: 100vw;

    .reg__seo-hero-banner {
        background-position: center;
        background-size: cover;
        width: 100%;
        margin: 20px 0;
        height: 275px;

        @media screen and (min-width: 1280px) {
            margin: 50px 0;
        }
    }

    .reg__seo {
        display: flex;
        flex-direction: column;
        gap: 50px;
        color: black;
        width: 85%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0;

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 0 20px;
            margin: 0;
        }

        .reg__seo-title {
            font-size: clamp(22px, 1.6vw, 26px);
            font-weight: 700;
        }

        .reg__seo-hero {
            .reg__seo-hero-content {
                width: 100%;
                padding: 25px 20px 0;

                .reg__seo-title {
                    padding-bottom: 25px;
                    text-align: center;
                }

                .reg__seo-content {
                    width: 100%;
                    line-height: 1.25rem;
                    text-align: center;

                    p {
                        font-size: 1.125rem;
                    }

                    a {
                        color: black;
                        font-weight: 700;
                    }
                }

                @media screen and (max-width: 768px) {
                    padding: 20px 0;
                    margin: 0;
                }
            }
        }

        .reg__seo-content-flex {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            min-height: 500px;

            .reg__seo-content-image {
                background-position: center;
                background-size: cover;
                flex: 1 1 50%;

                @media screen and (max-width: 768px) {
                    flex: unset;
                }
            }

            .reg__seo-content-inner {
                flex: 1 1 50%;
                padding-top: 25px;
                padding-bottom: 25px;
                padding-left: 75px;
                padding-right: 0px;

                .reg__seo-title {
                    line-height: 1.85rem;
                    padding: 15px 0;
                }

                .reg__seo-content {
                    font-size: 1rem;
                    line-height: 1.25rem;
                    font-weight: 400;

                    p {
                        font-size: 1.125rem;
                    }

                    a {
                        color: black;
                        font-weight: 700;
                    }
                }

                @media screen and (max-width: 768px) {
                    flex: unset;

                    .reg__seo-title,
                    .reg__seo-content {
                        text-align: center;
                    }
                }
            }

            @media screen and (max-width: 768px) {
                flex-direction: column;

                .reg__seo-content-image {
                    //Subtracting the left and right padding of .reg__seo-content-inner to match the width
                    width: calc(100% - 40px);
                    height: 300px;
                    margin: 0 auto;
                }

                .reg__seo-content-inner {
                    width: 100%;
                    padding: 20px;
                }

            }
        }

        .reg__seo-content-reverse {
            flex-direction: row-reverse;

            .reg__seo-content-inner {
                padding-right: 75px;
                padding-left: 0px;
            }

            @media screen and (max-width: 768px) {
                flex-direction: column;

                .reg__seo-content-inner {
                    padding: 20px;

                }
            }
        }
    }
}

.reg__form {
    background-color: #000000;
    padding-top: 15px;
    color: white;
    position: relative;
    margin-top: 50px;

    h3 {
        font-size: 1.5rem;
        font-weight: 400;
        text-align: center;
        margin-bottom: 10px;

        a {
            font-size: 1.5rem;
            color: $red;
            font-weight: 700;
            font-style: italic;

            &:hover,
            &:focus,
            &:active {
                text-decoration: underline;
            }
        }

        @media screen and (max-width: 768px) {
            text-align: center;

            a {
                display: block;
            }
        }
    }

    .sales-agent {
        height: 220px;
        width: 200px;
        position: absolute;
        bottom: 0;
        right: 0;

        &:not(.show-agent) {
            display: none;
        }

        @media screen and (min-width: 301px) and (max-width: 450px) {
            background-size: contain;
            background-repeat: no-repeat;
            width: 180px;
            height: 180px;
        }

        @media screen and (max-width: 300px) {
            display: none;
        }
    }

    .reg__form-container {
        width: 80%;
        margin: 0 auto;
        position: relative;

        .reg__hs-form {
            width: calc(100% - 200px);
            margin: 0 auto;

            h3 {
                font-weight: 700;
                font-size: 1.75rem;

                a {
                    font-size: 1.5rem;
                    font-weight: 700;
                }
            }

            h3>a {
                display: block;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
            }

            .submitted-message {
                padding: 100px 0;
                text-align: center;
            }

            form {
                display: flex;
                flex-wrap: wrap;

                fieldset,
                .hs_submit {
                    flex-basis: 100%;

                    .hs-button {
                        border-radius: 5px;
                        transition: background-color .2s ease-in-out;

                        &:hover,
                        &:focus,
                        &:active {
                            background-color: black;
                        }
                    }
                }

                textarea {
                    height: auto;
                    max-height: 2.625rem;
                    overflow: hidden;
                    resize: none;
                    padding: 13px 15px 5px;
                    white-space: nowrap;

                    &::placeholder {
                        color: black;
                        font-weight: 300;
                        letter-spacing: 1.5px;
                        white-space: nowrap;
                    }
                }

                .hs_opt_in a {
                    color: white;
                }

                input[type="checkbox"] {
                    min-width: 25px;
                }

                @media screen and (min-width: 769px) and (max-width: 1024px) {

                    input::placeholder,
                    textarea::placeholder,
                    select {
                        font-size: .75rem;
                    }

                    textarea {
                        padding: 15px 15px 5px;
                    }
                }

                @media screen and (max-width: 768px) {
                    .input>input.hs-input {
                        width: 100% !important;
                    }
                }
            }
        }

        @media screen and (min-width: 451px) and (max-width: 768px) {
            padding-top: 32px;
            padding-bottom: 48px;
        }

        @media screen and (max-width: 450px) {
            padding-top: 32px;
            padding-bottom: 32px;
            padding-left: 24px;
            padding-right: 24px;
            width: 100%;
        }
    }

    &.show-agent .reg__hs-form form {
        @media screen and (max-width: 768px) {
            .hs_opt_in {
                width: calc(100% - 125px);
                padding-top: 32px;

                .input {
                    margin: 0 15px;
                }
            }

            .hs_submit .actions {
                width: calc(100% - 125px);
            }
        }

        @media screen and (max-width: 425px) {
            .hs_opt_in {
                width: calc(100% - 150px);
                padding-top: 15px;

            }
        }

        @media screen and (max-width: 300px) {
            .hs_opt_in {
                width: 100%;
            }

            .hs_submit .actions {
                width: 100%;
            }
        }
    }
}

.reg__disclaimer_stLouis {

    padding: 20px 40px;

    @media screen and (max-width: 450px) {
        padding: 20px;
    }

    p {
        font-family: 'Open Sans', 'Arial', sans-serif;
        font-size: 12px;
        color: #7B7B7B;
        font-style: italic;
    }

}

.reg__seo-description_communities {
    padding: 20px 80px;

    h3 {
        font-size: 26px !important;
        font-weight: 400 !important;
    }
}